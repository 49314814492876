import { atom } from 'recoil';

const isInSuspenseAtom = atom<boolean>({
  key: 'isInSuspense',
  default: false,
});

const activeTooltipAtom = atom<string | null>({
  key: 'activeTooltip',
  default: null,
});

export { isInSuspenseAtom, activeTooltipAtom };
